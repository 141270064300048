import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import Audi_TT_Img from '@assets/images/Audi-TT-main-big.png'
import BlobImg from '@assets/images/blob.svg'
import { SCREENS } from "@/app/components/responsive";
import { Button } from "@/app/components/button";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const TopSectionContainer = styled.div`
  margin-top: 6em;
  ${tw`
        w-full
        max-w-screen-2xl
        flex
        justify-between
        sm:min-h-[300px]
        md:min-h-[400px]
        // lg:min-h-[500px]
        pl-3
        pr-3
        lg:pl-12
        lg:pr-12
    `}
`

const LeftContainer = styled.div`
  ${tw`
        ml-3
        w-1/2
        flex
        flex-col
    `}
`

const RightContainer = styled.div`
  ${tw`
        w-1/2
        flex
        flex-col
        relative
        mt-20
    `}
`

const Slogan = styled.h1`
  ${tw`
        font-bold
        text-2xl
        xl:text-6xl
        sm:text-3xl
        md:text-5xl
        lg:font-black
        md:font-extrabold
        text-black
        mb-4
        sm:leading-snug
        lg:leading-normal
        xl:leading-relaxed
    `}
`

const Description = styled.p`
  ${tw`
        text-xs
        lg:text-sm
        xl:text-lg
        sm:max-h-full
        // overflow-visible
        // max-h-12
        text-gray-800
    `}
`

const BlobContainer = styled.div`
    width: 20em;
    height: 10em;
    position: absolute;
    right: -5em;
    top: -9em;
    z-index: -1;
    transform: rotate(-30deg);

    img {
      width: 100%;
      height: auto;
      max-height: max-content;
    }

    @media (min-width: ${SCREENS.sm}) {
      width: 40em;
      max-height: 10em;
      right: -9em;
      top: -16em;
      transform: rotate(-25deg);
    }

    @media (min-width: ${SCREENS.lg}) {
      width: 50em;
      max-height: 30em;
      right: -7em;
      top: -15em;
      transform: rotate(-30deg);
    }

    @media (min-width: ${SCREENS.xl}) {
      width: 70em;
      max-height: 30em;
      right: -15em;
      top: -25em;
      transform: rotate(-20deg);
    }
`

const StandaloneCar = styled.div`
    width: auto;
    height: 10em;
    right: -6em;
    top: -5em;
    position: absolute;

    img {
      width: auto;
      height: 100%;
      max-width: fit-content;
    }

    @media (min-width: ${SCREENS.sm}) {
        height: 15em;
        right: -6em;
        top: -6em;
    }

    @media (min-width: ${SCREENS.lg}) {
        height: 21em;
        right: -8em;
        top: -5em;
    }

    @media (min-width: ${SCREENS.xl}) {
        height: 30em;
        right: -13em;
        top: -9em;
    }

`

const ButtonContainer = styled.div`
  ${tw`
    flex
    // flex-wrap
    mt-4
    mb-4
  `}
`

export function TopSection() {

  const isMobile = useMediaQuery({ maxWidth: SCREENS.xs })

  const solgan = "Die Seite wurde nicht gefunden?"
  const description = ` Es sieht so aus als hätten Sie sich verfahren, beginnen Sie noch mal auf der HomePage...`

  if (isMobile) {
    return (
      <>
        <TopSectionContainer>
          <LeftContainer>
            <Slogan>{solgan}</Slogan>
            <Description>
              {description}
            </Description>
          </LeftContainer>
          <RightContainer>
            <BlobContainer>
              <img src={BlobImg} alt="blobImg" />
            </BlobContainer>
            <StandaloneCar>
              <img src={Audi_TT_Img} alt="Audi TT RS" />
            </StandaloneCar>
          </RightContainer>
        </TopSectionContainer>
        <ButtonContainer>
          <Link to="/" >
            <Button text="HomePage" theme="outlined" />
          </Link>
        </ButtonContainer>
      </>
    )
  }

  return (
    <TopSectionContainer>
      <LeftContainer>
        <Slogan>{solgan}</Slogan>
        <Description>
          {description}
        </Description>
        <ButtonContainer>
          <Link to="/">
            <Button text="HomePage" theme="outlined" />
          </Link>
        </ButtonContainer>
      </LeftContainer>
      <RightContainer>
        <BlobContainer>
          <img src={BlobImg} alt="blobImg" />
        </BlobContainer>
        <StandaloneCar>
          <img src={Audi_TT_Img} alt="Audi TT RS" />
        </StandaloneCar>
      </RightContainer>
    </TopSectionContainer>
  );

}
