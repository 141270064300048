import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "react-router-dom";


interface Meet {
    id: string
    attributes: {
        title: string
        description: string
        NextCloud_Media_URL: string
        thumbnail: string
        time: string
    }
}

const Box = styled.div`
     ${tw`
       flex flex-col justify-center w-full mb-5 mt-5
    `}
    div#box {
        ${tw`
            relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0
            rounded-xl shadow-lg shadow-red-500 p-3 max-w-xs md:max-w-[80vw] mx-auto
            border border-red-500 bg-white
        `}
        div#logo {
            ${tw`
                w-full md:w-1/3 bg-white grid place-items-center mb-[-0.5em] md:mb-auto
            `}
            img {
                ${tw`
                    rounded-xl
                    w-full
                `}
            }
        }

        div#rightside {
            ${tw`
                w-[95%] md:w-2/3 bg-white flex flex-col space-y-2 p-3
            `}

            div#header {
                ${tw`
                    flex justify-between items-center w-[95%] md:ml-4 h-7 mb-[0.5em] md:m-auto md:h-auto
                `}
                 p {
                    ${tw`
                        text-gray-500 font-medium md:block
                    `}
                }

                 a {
                    text-decoration:none;
                    ${tw`
                        bg-gray-200 px-4 py-1 rounded-full text-base font-medium text-gray-800 md:block
                    `}
                }
            }
             h3#title {
                ${tw`
                    font-black text-gray-800 md:text-3xl text-xl
                `}
            }
             p#description {
                ${tw`
                    md:text-lg text-gray-500 text-base
                `}
            }
        }
    }
`

export default function Container(data: Meet) {


    return <>
        <Box key={data.id}>
            <div id="box">
                <div id="logo">
                    <img src={data.attributes.thumbnail} alt={data.attributes.title + 'thumbnail'} />
                </div>
                <div id="rightside">
                    <div id="header">
                        <p>{data.attributes.time}</p>
                        <Link to={data.attributes.NextCloud_Media_URL} target="_blank">Bilder</Link>
                    </div>
                    <h3 id="title">{data.attributes.title}</h3>
                    <p id="description">{data.attributes.description}</p>
                </div>
            </div>
        </Box>
    </>
}