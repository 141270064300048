import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import useFetch from "@/app/hooks/useFetch";
import { Navbar } from '@components/navbar';
import { Link } from "react-router-dom";
import Container from "./Container";
import CustomSpinner from "@components/spinner";

const PageContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        h-full
        items-center
        overflow-x-hidden
    `}
`;
const Placeholder = styled.div`

    ${tw`
        flex
        w-[60%]
        md:w-auto
        items-center
        justify-center
        flex-wrap
    `}

    h1 {
        ${tw`
            font-black text-gray-800 md:text-3xl text-xl  
        `}
    }
`

const SpinnerBox = styled.div`
    ${tw`
        flex
        items-center
        justify-center
        w-full
        h-[50vh]
    `}
`;


export default function Projects() {

    var url: string = process.env.REACT_APP_STRAPI_BACKEND_URL + 'projects'
    const { loading, error, data } = useFetch(url)

    var projects = null

    if (data != null) {
        projects = data['data']
    }

    console.log(projects)

    type Project = {
        id: string
        attributes: {
            title: string
            description: string
            NextCloud_Media_URL: string
            thumbnail: string
            year: number
            status: string
            availability: string
        }
    }


    if (error) return <p>Irgenwas ist schiefgelaufen... 😅</p>

    var slogan = "Exklusive Umbauten. Pure Leidenschaft."

    if (loading) {
        return <PageContainer>
            <Navbar />
            <Placeholder>
                <h1>{slogan}</h1>
                {/* <h2>Erleben Sie die Legenden unserer einzigartigen Projekte.</h2> */}
            </Placeholder>

            <SpinnerBox tw="min-h-[80vh]">
                <CustomSpinner />
            </SpinnerBox>

        </PageContainer>
    }

    return <PageContainer>
        <Navbar />
        <Placeholder>
            <h1>{slogan}</h1>
            {/* <h2>Erleben Sie die Legenden unserer einzigartigen Projekte.</h2> */}
        </Placeholder>


        {(projects as unknown as Project[]).map(object => (
            <Container {...object} />
        ))}


    </PageContainer>
}