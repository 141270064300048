import { SCREENS } from "@/app/components/responsive";
import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";

import BMW_M4_IMG from "@assets/images/BMW_M4.png";
import { useMediaQuery } from "react-responsive";

const AboutUsContainer = styled.div<{ isMobile?: boolean }>`
    ${tw`
        w-full
        flex
        xs:flex-col
        items-center
        justify-center
        pt-4
        pb-4
        pr-7
        pl-7
        md:pl-0
        md:pr-0
        bg-white
    `}
`

const CarContainer = styled.div<{ flipped?: boolean }>`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: -30px; */

    img {
        width: 80%;
        height: auto;
        border-radius: 2rem;
        box-shadow: 1rem 1rem 1rem #ccc ;
    }
    ${({ flipped }) =>
        flipped &&
        css`
        img {
            ${tw`
                mt-5
            `}
        }
    `};

    @media (min-width: ${SCREENS.md}) {
        height: auto;
        width: 50%;
    }

    @media (min-width: ${SCREENS.lg}) {
        height: auto;
    }

    @media (min-width: ${SCREENS["2xl"]}) {
        height: auto;
        margin-left: 0;
    }
`

const InfoContainer = styled.div<{ flipped?: boolean }>`
    max-width: 80%;
    ${tw`
        md:w-1/2
        items-center
        md:items-start
        flex
        flex-col
        md:ml-6
        2xl:ml-16
    `}
    ${({ flipped }) =>
        flipped &&
        css`
        max-width: 80%;
        ${tw`
            md:w-1/2
            items-center
            md:items-start
            flex
            flex-col
            md:ml-6
            2xl:ml-16
        `}
    `};
`


const Title = styled.h1`
    ${tw`
        text-black
        text-2xl
        md:text-5xl
        font-extrabold
        md:font-black
        md:leading-normal
    `}
`

const InfoText = styled.p`
    ${tw`
        max-w-2xl
        text-sm
        md:text-base
        text-gray-500
        font-normal
        mt-4
    `}
`

export function InformationBox({ flipped = false, title = "", text = "", img = "", img_alt = "" }) {

    const isMobile = useMediaQuery({ maxWidth: SCREENS.xs })

    if (flipped) {
        return (
            <AboutUsContainer isMobile>
                <InfoContainer flipped>
                    <Title>{title}</Title>
                    <InfoText>
                        {text}
                    </InfoText>
                </InfoContainer>
                <CarContainer flipped>
                    <img src={img} alt={img_alt} />
                </CarContainer>
            </AboutUsContainer>
        )
    }
    return (
        <AboutUsContainer>
            <CarContainer>
                <img src={img} alt={img_alt} />
            </CarContainer>
            <InfoContainer>
                <Title>{title}</Title>
                <InfoText>
                    {text}
                </InfoText>
            </InfoContainer>
        </AboutUsContainer>
    )
}