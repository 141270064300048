import React from 'react';
import styled from "styled-components";
import tw from "twin.macro";

const Spinner = styled.div`
    ${tw`
        inline-block h-12 w-12 animate-spin
         rounded-full border-4  border-red-500 border-solid border-current border-r-transparent
          align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]
    `}
    span {
        ${tw`
            !absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]
        `}
    }
`;


export default function CustomSpinner() {
    return <>
        <Spinner role="status">
            <span>Loading...</span>
        </Spinner>
    </>
}