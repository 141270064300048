import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { slide as Menu } from "react-burger-menu";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "../responsive";
import menuStyles from "./MenuStyles";
import { Link } from "react-router-dom";

const ListContainer = styled.ul`
    ${tw`
        flex
        list-none
    `}
    padding-inline-start: 0;
`

const NavItem = styled.li<{ menu?: boolean }>`
    ${tw`
        text-sm
        md:text-base
        text-black
        font-medium
        mr-1
        md:mr-5
        cursor-pointer
        transition
        duration-300
        ease-in-out
        hover:text-gray-700
    `}
    a {
        ${tw`
        text-sm
        md:text-base
        text-black
        font-medium
        hover:text-gray-700
        transition
        duration-300
        ease-in-out
        no-underline
        `}
    }

    ${({ menu }) =>
        menu &&
        css`
        ${tw`
            text-white
            text-xl
            mb-3
            focus:text-white
        `}
      a {
        ${tw`
            text-white
            text-xl
            focus:text-white
        `}
      }
    `};
`

export function NavItems() {

    const isMobile = useMediaQuery({ maxWidth: SCREENS.sm })

    if (isMobile)
        return (
            <Menu right styles={menuStyles}>
                <ListContainer>
                    <NavItem menu>
                        <Link to="/">Home</Link>
                    </NavItem>
                    <NavItem menu>
                        <Link to="/projects">Projekte</Link>
                    </NavItem>
                    <NavItem menu>
                        <Link to="/meets">Treffen</Link>
                    </NavItem>
                    {/* <NavItem menu>
                        <Link to="/about-us">Über uns</Link>
                    </NavItem> */}
                    <NavItem menu>
                        <Link to="/contact">Kontakt</Link>
                    </NavItem>
                </ListContainer>
            </Menu>
        )

    return (
        <ListContainer>
            <NavItem>
                <Link to="/">Home</Link>
            </NavItem>
            <NavItem>
                <Link to="/projects">Projekte</Link>
            </NavItem>
            <NavItem>
                <Link to="/meets">Treffen</Link>
            </NavItem>
            {/* <NavItem>
                <Link to="/about-us">Über uns</Link>
            </NavItem> */}
            <NavItem>
                <Link to="/contact">Kontakt</Link>
            </NavItem>
        </ListContainer>
    )
}