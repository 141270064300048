
import React from 'react';
import './App.css';
import styled from 'styled-components';
import tw from 'twin.macro';
import { HomePage } from './app/pages/Home/index';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ContactPage } from './app/pages/Contact/index';
import { NoPage } from './app/pages/NoPage/index';
import { Footer } from './app/components/footer';
import { Meets } from './app/pages/Meets';
import { Impressum } from './app/pages/Impressum';
import Projects  from './app/pages/Projects';
import { Navbar } from '@components/navbar';

const AppContainer = styled.div`
  ${tw`
    w-full
    min-h-full
    flex
    flex-col
  `}
`

function App() {
  return (
    <AppContainer>
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/projects/:id?" element={<Projects />} />
          <Route path="/meets/:id?" element={<Meets />} />
          {/* <Route path="about-us" element={<HomePage />} /> */}
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AppContainer>
  );
}

export default App;
