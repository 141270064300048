import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Navbar } from '@components/navbar';
import { TopSection } from "./topSection";
import { BookCard } from "@components/bookCard";
import { Marginer } from "@components/marginer";
import { BookingSteps } from "./bookingSteps";
import { InformationBox } from "@components/InformationBox";
import { TopCars } from "./topCars";
import Audi_TT_Showcase from "@assets/images/Audi TT showcase.jpg";
import Audi_TT_Back from "@assets/images/Audi TT Back.jpg";
import Audi_TT_Team from "@assets/images/Audi TT RS Team.jpg";
import Audi_TT_Trunk from "@assets/images/Audi TT RS Trunk.jpeg";
import { VideoBox } from "@components/VideoBox";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "@components/responsive";

const PageContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        h-full
        items-center
        overflow-x-hidden
    `}
`;


export function HomePage() {

    const isMobile = useMediaQuery({ maxWidth: SCREENS.xs })

    return <PageContainer>
        <Navbar />
        <TopSection />
        <Marginer direction="vertical" margin="4em" />
        {/* <BookCard /> */}
        {!isMobile ? (<Marginer direction="vertical" margin="5em" />) : ("")}
        <VideoBox
            video='https://wolke.motorsport-legenden.com/s/9YfYZ6ZmbwBDLYj/download?path=&files=MOTOR%20TV22_%20Die%20Sonder-Fahrzeuge%20bei%20den%20QuattroLegenden%20in%20St.%20Gilgen%202024.mp4'
            video_thumbnail="https://wolke.motorsport-legenden.com/apps/files_sharing/publicpreview/9YfYZ6ZmbwBDLYj?file=/TV22_Interview_Thumbnail.jpg&fileId=4309&x=1920&y=1080"
        />
        {/* <TopCars /> */}
        <Marginer direction="vertical" margin="4em" />
        {/* <BookingSteps/> */}
        <Marginer direction="vertical" margin="5em" />
        <InformationBox
            title="Über uns"
            text="Bei Motorsport Legenden verbinden wir Leidenschaft für Geschwindigkeit und Technik mit höchster Handwerkskunst. Unser Ziel ist es, außergewöhnliche Fahrzeuge zu schaffen, die nicht nur auf der Straße, sondern auch im Herzen unserer Kunden einzigartig sind. Jedes unserer Fahrzeuge wird mit größter Sorgfalt und Präzision umgebaut, um höchste Performance und Exklusivität zu garantieren."
            img={Audi_TT_Showcase}
            img_alt="BMW_M4.png"
        />
        <Marginer direction="vertical" margin="3em" />
        <InformationBox
            flipped
            title="Unsere Philosophie"
            text="Wir glauben, dass ein Auto mehr als nur ein Fortbewegungsmittel ist. Es ist ein Ausdruck von Persönlichkeit, Leidenschaft und Stil. Bei Motorsport Legenden setzen wir alles daran, diese Werte in jedem unserer Umbauten zu verwirklichen. Durch die Verwendung hochwertigster Materialien und modernster Technologie garantieren wir, dass jedes unserer Fahrzeuge höchsten Ansprüchen gerecht wird."
            img={Audi_TT_Back}
            img_alt="porsche_IMG"
        />
        <Marginer direction="vertical" margin="3em" />
        <InformationBox
            title="Kontakt"
            text="Interessiert an einem unserer limitierten Umbauten? Kontaktieren Sie uns noch heute und werden Sie Teil der Motorsport Legenden Familie. Unser Team stehen Ihnen jederzeit zur Verfügung, um Ihre Fragen zu beantworten und Sie bei der Auswahl Ihres Traumwagens zu unterstützen."
            img={Audi_TT_Team}
            img_alt=""
        />
        <InformationBox
            flipped
            title="Motorsport Legenden – Wo Träume zur Wirklichkeit werden."
            text="Erleben Sie die Faszination von exklusiven Sportwagenumbauten und sichern Sie sich ein Stück Motorsportgeschichte. Besuchen Sie uns und lassen Sie sich von unserer Leidenschaft für Perfektion und Innovation begeistern."
            img={Audi_TT_Trunk}
            img_alt="Audi_TT_main_middle_IMG"
        />
        <Marginer direction="vertical" margin="5em" />
    </PageContainer>
}