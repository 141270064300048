import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "@/app/components/responsive";
import { useForm, SubmitHandler } from "react-hook-form"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Section = styled.section`
    ${tw`
        bg-white dark:bg-gray-900
    `}
`;
const SectionDiv = styled.div`
    ${tw`
        py-8 lg:py-16 px-4 mx-auto max-w-screen-md
    `}
    h2 {
        ${tw`
            mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white
        `}
    }
    p {
        ${tw`
            mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl
        `}
    }
    form {
        ${tw`
            space-y-8
        `}

        button {
            ${tw`
                py-3 px-5 
                text-sm
                md:text-base
                font-medium
                md:font-bold
                text-center 
                text-white 
                rounded-lg
                sm:w-fit 
                bg-red-500 
                shadow-md
                shadow-red-500
                border
                border-gray-300
                hover:text-red-500
                hover:border-[#3b82f6]

                transition-all
                duration-200
                ease-in-out
                hover:bg-transparent
                hover:outline-none hover:ring-[#3b82f6]
                dark:bg-[#2563eb] dark:hover:bg-[#1d4ed8] dark:focus:ring-[#1e40af]
            `}
        }
    }
`;

const FormDivEmail = styled.div<{ mobile?: boolean }>`
    label {
        ${tw`
            block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300
        `}
    }
    input {
        ${tw`
            shadow-md shadow-red-500 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
            focus:ring-[#3b82f6] focus:border-[1.5px] focus:border-[#3b82f6] focus:outline-none block w-full p-2.5 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-[#3b82f6] dark:focus:border-[#3b82f6] dark:shadow-sm-light
        `}
    }

    ${({ mobile }) =>
        mobile &&
        css`
            input {
                width: 95%!important;
                ${tw`
                    shadow-md shadow-red-500 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:ring-[#3b82f6] focus:border-[1.5px] focus:border-[#3b82f6] focus:outline-none block p-2.5 
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                    dark:focus:ring-[#3b82f6] dark:focus:border-[#3b82f6] dark:shadow-sm-light
                `}
            }
    `};
`;
const FormDivSubject = styled.div<{ mobile?: boolean }>`
    ${tw`

    `}
    label {
        ${tw`
            block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300
        `}
    }
    input {
        ${tw`
            shadow-md shadow-red-500 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
            focus:ring-[#3b82f6] focus:border-[1.5px] focus:border-[#3b82f6] focus:outline-none block w-full p-2.5
             dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
              dark:focus:ring-[#3b82f6] dark:focus:border-[#3b82f6] dark:shadow-sm-light
        `}
    }
    ${({ mobile }) =>
        mobile &&
        css`
            input {
                width: 95%!important;
                ${tw`
                    shadow-md shadow-red-500 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                    focus:ring-[#3b82f6] focus:border-[1.5px] focus:border-[#3b82f6] focus:outline-none block p-2.5
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                    dark:focus:ring-[#3b82f6] dark:focus:border-[#3b82f6] dark:shadow-sm-light
                `}
            }
    `};
`;
const FormDivMessage = styled.div<{ mobile?: boolean }>`
    ${tw`
        sm:col-span-2
    `}
    label {
        ${tw`
            block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400
        `}
    }
    ${({ mobile }) =>
        mobile &&
        css`
            textarea {
                width: 95%!important;
                ${tw`

                    block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-md shadow-red-500 border border-gray-300
                    focus:ring-[#3b82f6] focus:border-[1.5px] focus:border-[#3b82f6] focus:outline-none
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                    dark:focus:ring-[#3b82f6] dark:focus:border-[#3b82f6]
                `}
            }
    `};
    textarea {
        ${tw`
            block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-md shadow-red-500 border border-gray-300
            focus:ring-[#3b82f6] focus:border-[1.5px] focus:border-[#3b82f6] focus:outline-none
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
            dark:focus:ring-[#3b82f6] dark:focus:border-[#3b82f6]
        `}
    }
`;


export default function Contactbox() {
    const isMobile = useMediaQuery({ maxWidth: SCREENS.sm })

    var Props = {
        mobile: isMobile
    }

    const {
        register,
        reset,
        handleSubmit,
        formState,
        formState: { isSubmitSuccessful },
    } = useForm<FormValues>()

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset(undefined, {
                keepValues: false,
            })
        }


    }, [formState, reset])

    type FormValues = {
        subject: string
        message: string
        email: string
    }

    const onSubmit: SubmitHandler<FormValues> = async (data) => {

        var apiKey: string = 'Bearer ' + process.env.REACT_APP_STRAPI_BACKEND_APIKEY
        var url: string = process.env.REACT_APP_STRAPI_BACKEND_URL + 'messages'
        // Do something
        var obj = {
            data: {
                email: data.email,
                subject: data.subject,
                message: data.message,
            }
        }

        const postData = async () => {
            try {
                const res = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': apiKey
                    },
                    method: "POST",
                    body: JSON.stringify(obj)
                })
                return res

            } catch (e) {
                console.log(e)
            }
        }
        const res = await postData()
        console.log("Message sent: " + res?.status)
        toast.success('Nachricht gesendet ✔', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce
        });
    }

    return <>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
        />
        <Section>
            <SectionDiv>
                <h2>Kontakt</h2>
                <p>Interessiert an einem unserer limitierten Umbauten? Eine Frage? Oder auch nur eine Anmerkung? Lass es uns wissen mit dem Kontaktformular!</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormDivEmail {...Props}>
                        <label htmlFor="email">Deine Email Adresse</label>
                        <input
                            type="email"
                            id="email"
                            {...register("email")}
                            placeholder="Deine Email Adresse"
                            required></input>
                    </FormDivEmail>
                    <FormDivSubject {...Props}>
                        <label htmlFor="subject">Thema</label>
                        <input
                            type="text"
                            id="subject"
                            {...register("subject")}
                            placeholder="Lass uns wissen, wie wir dir helfen können"
                            required></input>
                    </FormDivSubject>
                    <FormDivMessage {...Props}>
                        <label htmlFor="message">Deine Nachricht</label>
                        <textarea
                            id="message"
                            rows={6}
                            {...register("message")}
                            placeholder="Hinterlasse einen Kommentar..."></textarea>
                    </FormDivMessage>
                    <button type="submit">Nachricht senden</button>
                </form>
            </SectionDiv>
        </Section>
    </>
}