import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import CarLogoImg from "@assets/images/car-logo.png"
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "../responsive";
import { Link } from "react-router-dom";

const FooterDiv = styled.div`
    ${tw`
        bg-white
        rounded-lg
        shadow
        dark:bg-gray-900
        m-4
    `}
`
const Box1 = styled.div`
    ${tw`
        w-full max-w-screen-xl mx-auto p-4 md:py-8
    `}
`
const Box2 = styled.div`
    ${tw`
        sm:flex sm:items-center sm:justify-between
    `}
`
const LogoBox = styled.a`
    ${tw`
        flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse
        xs:justify-center
    `}
`
const LogoImg = styled.img`
    ${tw`
        h-8
    `}
`

const List = styled.ul`
    list-style: none;
    ${tw`
        flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400
    `}
`
const ListLi = styled.li`
    a {
        text-decoration: none;
    color: black;
        ${tw`
            text-sm
        md:text-base
        hover:text-gray-700
        md:me-6
        me-4
        `}
    }
`

const HR = styled.hr`
    ${tw`
        my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8
        xs:max-w-[theme(maxWidth.HR)]
    `}
`

const Span = styled.span`
    ${tw`
        block
        text-sm
        md:text-base
        text-gray-500
        sm:text-center
        dark:text-gray-400
        xs:justify-center
        xs:flex
    `}
    a {
        color: black;
        text-decoration:none;
        ${tw`
            text-sm
            md:text-base
            hover:text-gray-700
        `}
    }
`
export function Footer() {
    const currentYear = new Date().getFullYear();

    const isMobile = useMediaQuery({ maxWidth: SCREENS.xs })

    return (
        <FooterDiv>
            <Box1>
                <Box2>
                    <LogoBox href="/">
                        <LogoImg src={CarLogoImg} alt="Motorsport Legenden Logo" />
                        {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Motorsport Legenden</span> */}
                    </LogoBox>
                    <List>
                        <ListLi>
                            <Link to="/projects">Projekte</Link>
                        </ListLi>
                        <ListLi>
                            <Link to="/meets">Treffen</Link>
                        </ListLi>
                        <ListLi>
                            <Link to="/impressum">Impressum</Link>
                        </ListLi>
                        <ListLi>
                            <Link to="/contact">Kontakt</Link>
                        </ListLi>
                    </List>
                </Box2>
                <HR />
                <Span>© {currentYear} <a href="https://alhumedia.com/" target="_blank" rel="noreferrer">ALHU Media™</a>. All Rights Reserved.</Span>
            </Box1>
        </FooterDiv>

    )

}
