import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Navbar } from '@components/navbar';
import { Marginer } from "@/app/components/marginer";
import Contactbox from './Contactbox';


const PageContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        h-full
        items-center
    `}
`;


export function ContactPage() {
    return <PageContainer>
        <Navbar />
        <Contactbox />
        <Marginer direction="vertical" margin="5em" />
    </PageContainer>
}