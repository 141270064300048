import { SCREENS } from "@/app/components/responsive";
import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";

import BMW_M4_IMG from "@assets/images/BMW_M4.png";
import { useMediaQuery } from "react-responsive";

const AboutUsContainer = styled.div<{ isMobile?: boolean }>`
    ${tw`
        w-full
        flex
        xs:flex-col
        items-center
        justify-center
        pt-4
        pb-4
        pr-7
        pl-7
        md:pl-0
        md:pr-0
        bg-white
    `}
`

const CarContainer = styled.div<{ flipped?: boolean }>`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        width: 80%;
        height: auto;
        border-radius: 2rem;
        box-shadow: 1rem 1rem 1rem #F05252 ;
    }
    ${({ flipped }) =>
        flipped &&
        css`
        video {
            ${tw`
                mt-5
            `}
        }
    `};

    @media (min-width: ${SCREENS.md}) {
        height: auto;
        width: 80%;
    }

    @media (min-width: ${SCREENS.lg}) {
        height: auto;
    }

    @media (min-width: ${SCREENS["2xl"]}) {
        height: auto;
        margin-left: 0;
    }
`



export function VideoBox({ video = "", video_thumbnail ="" }) {

    return (
        <AboutUsContainer>
            <CarContainer>
                <video
                    src={video}
                    controls
                    controlsList="nodownload"
                    poster={video_thumbnail}
                    playsInline
                />
            </CarContainer>

        </AboutUsContainer>
    )
}