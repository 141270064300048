import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Navbar } from '@components/navbar';
import { Marginer } from "@components/marginer";
import { InformationBox } from "@components/InformationBox";
import BMW_M4_IMG from "@assets/images/BMW_M4.png";
import porsche_IMG from "@assets/images/porsche.png";
import Audi_TT_main_middle_IMG from "@assets/images/Audi-TT-main-middle.png";

const PageContainer = styled.div`
    ${tw`
        flex
        flex-col
        w-full
        h-full
        items-center
        overflow-x-hidden
    `}
`;

const Box = styled.div`
    ${tw`
        m-16
        flex
        flex-col
        items-center
        justify-center
    `}
`


export function Impressum() {
    return <PageContainer>
        <Navbar />
        <Box>
            <h2>Impressum</h2><p>Markus Kauderer<br />Ulmweg 6<br />86529 Schrobenhausen</p>
            <p>E-Mail: <a href="mailto:markus.kauderer@motorsport-legenden.c
om">markus.kauderer@motorsport-legenden.com</a><br /></p><br /><h2>Hinweis gemä&szlig; Online-Streitbeilegungs-Verordnung</h2><p>Nach geltendem Recht sind
                wir verpflichtet, Verbraucher auf die Existenz der Europ&auml;ischen Online-Stre
                itbeilegungs-Plattform hinzuweisen, die f&uuml;r die Beilegung von Streitigkeite
                n genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. F&uuml;r
                die Einrichtung der Plattform ist die Europ&auml;ische Kommission zust&auml;ndi
                g. Die Europ&auml;ische Online-Streitbeilegungs-Plattform ist hier zu finden: <a
                    href="http://ec.europa.eu/odr" target="_blank" rel="nofollow">http://ec.europa.
                    eu/odr</a>. Unsere E-Mail lautet: <a href="mailto:markus.kauderer@motorsport-leg
enden.com">markus.kauderer@motorsport-legenden.com</a></p><p>Wir weisen aber dar
                auf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen
                der Europ&auml;ischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Si
                e zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.</p><br /><br
            /><h2>Disclaimer – rechtliche Hinweise</h2>§ 1 Warnhinweis zu Inhalten<br />Die
            kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglic
            her Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewäh
            r für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei z
            ugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnet
            e Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung d
            es Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglich
            en Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbiet
            er zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.<br /><br /
            >§ 2 Externe Links<br />Diese Website enthält Verknüpfungen zu Websites Dritter
            ("externe Links"). Diese Websites unterliegen der Haftung der jeweiligen Betreib
            er. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die frem
            den Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeit
            punkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einflus
            s auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften
            Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter di
            e hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige K
            ontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rech
            tsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derart
            ige externe Links unverzüglich gelöscht.<br /><br />§ 3 Urheber- und Leistungsschutzrechte<br />Die auf dieser Website veröffentlichten Inhalte unterliegen dem
            deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Lei
            stungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlich
            en Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesonde
            re für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung
            bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien u
            nd Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Di
            e unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter
            Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien un
            d Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist
            erlaubt.<br /><br />Die Darstellung dieser Website in fremden Frames ist nur mit
            schriftlicher Erlaubnis zulässig.<br /><br />§ 4 Besondere Nutzungsbedingungen<
                br />Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den
            vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich d
            arauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besondere
            n Nutzungsbedingungen.
        </Box>

    </PageContainer>
}