import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Logo } from '@components/logo/index';
import { NavItems } from "./navItems";

const NavbarContainer = styled.div`
    min-height: 68px;
    ${tw`
        w-full
        max-w-screen-2xl
        flex
        flex-row
        items-center
        justify-center
        sm:justify-between
        md:justify-between
        xl:justify-between
        2xl:justify-between
        ml-12
        mr-12
    `}
`

const LogoContainer = styled.div`
    ${tw`
        sm:ml-12
        md:ml-12
        xl:ml-12
        2xl:ml-12
    `}
`

export function Navbar() {
    return (
        <NavbarContainer>
            <a href="/">
                <LogoContainer>
                    <Logo />
                </LogoContainer>
            </a>
            <NavItems />
        </NavbarContainer>
    )
}